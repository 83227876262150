@import '~@sevensenders/component-library/styles/colors';

:root {
  --colors-white: #{$white};
  --colors-snow: #{$snow};
  --colors-smoke: #{$smoke};
  --colors-silver: #{$silver};
  --colors-isabeline: #{$isabeline};
  --colors-light-grey: #{$light-grey};
  --colors-dark-grey: #{$dark-grey};
  --colors-dark-orange: #{$dark-orange};
  --colors-sky-blue: #{$sky-blue};
  --colors-filter-blue: #{$filter-blue};
  --colors-lightest-sky-blue-two: #{$lightest-sky-blue-two};
  --colors-lightest-orange: #{$lightest-orange};
  --colors-lightest-orange-hover: #{rgba($lightest-orange, 0.6)};
  --colors-dark-red: #{$dark-red};
  --colors-lighter-red: #{$lighter-red};
  --colors-lightest-red: #{$lightest-red};
  --colors-lightest-red-hover: #{rgba($lightest-red, 0.6)};
}

body {
  margin: 0;
}

h1, h2 {
  margin: 0;
  font-size: 20px !important;
}

h3 {
  margin: 0 0 4px;
}

main {
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow: auto;

  &.loading {
    overflow: hidden;
  }
}

.subdued {
  color: $grey;
  font-family: 'Public Sans', sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
}
