@import '~@sevensenders/component-library/styles/colors';

.App.TwoColumn main {
  display: flex;
  padding: 0;

  .TwoColumn__SubNav {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    padding: 24px 12px;
    background: $smoke;
    max-height: 100vh;
    overflow: auto;

    .TwoColumn__SubNavHeader {
      margin: 0 12px 26px;
    }

    .TwoColumn__SubNavLink {
      font-family: 'Public Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $dark-grey;
      padding: 8px 28px 8px 12px;
      border-radius: 4px;
      display: block;
      text-decoration: none;

      &.active {
        color: $sky-blue;
        background-color: $light-grey;
      }
    }
  }

  section {
    padding: 24px 32px;
    position: relative;
    width: 100%;
    max-height: 100vh;
    overflow: auto;
  }
}
