@import '~@sevensenders/component-library/styles/colors';

$banner-image: './images/banner-1x.png';
$banner-image2x: './images/banner-2x.png';

.login-page {
  display: flex;

  .banner {
    width: 460px;
    max-width: 460px;
    min-width: 460px;
    padding: 32px;
    height: 100vh;
    background-image: url($banner-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) { 
      background-image: url($banner-image2x);
    }
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;

    .login-form {
      width: 437px;
      margin: auto;
      padding: 50px 0;
      position: relative;
    }

    .inputContainer > div:nth-child(2n) {
      margin-bottom: 24px;
    }

    .inputContainer > div:last-child {
      margin-bottom: 32px;
    }

    h1 {
      margin: 0 0 8px;
    }

    .subtext {
      display: block;
      font-size: 14px;
      color: $grey;
      margin-bottom: 32px;
    }

    .forgot-password-form .inputContainer {
      margin-bottom: 32px;

      > div:nth-child(2n) {
        margin-bottom: 24px;
      }

      > div:nth-last-child(2) {
        margin-bottom: 4px;
      }
    }
  }
}
